import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {regular, solid} from "@fortawesome/fontawesome-svg-core/import.macro";
import {Rating as MUIRating} from "@mui/material";
import {twMerge} from "tailwind-merge";

export default function Rating({className, ...props}) {
  return (
    <MUIRating
      icon={<FontAwesomeIcon icon={solid('star')} />}
      emptyIcon={<FontAwesomeIcon icon={regular('star')} />}
      className={twMerge('text-primary text-sm shrink-0', className)}
      {...props}
    />
  )
}
