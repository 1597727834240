import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {solid} from "@fortawesome/fontawesome-svg-core/import.macro";
import {Button, Checkbox, FormControl, FormControlLabel, MenuItem, OutlinedInput, Select} from "@mui/material";
import _ from "lodash";
import ProductCard from "components/cards/ProductCard";
import React, {useEffect, useState} from "react";
import {checkMobile, sortByItem, toggleArray} from '../../utils/helper'
import config from '../../data/config.json'
import {useTranslation} from "next-i18next";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CollapseList from "components/CollapseList";

const SORT_OPTIONS = config.product.sort
const STRAIN_TYPES = config.product.strainType
const PRICE_RANGES = config.product.priceRange
const WEIGHTS = config.product.weight
const filter_key_map = {
  categories: 'category',
  deals: 'deal',
  brandVerified: 'brand_verified',
  brands: 'brand',
  priceRanges: 'price',
  weights: 'weight',
  strainTypes: 'strain_type'
}
export default function ProductListing(props) {
  const {
    products, deals, brands, baseUrl, type,
    _sort, _filter, _page, category
  } = props

  const { t } = useTranslation()

  const [sort, setSort] = useState(SORT_OPTIONS.find(s => s.sort.sortBy === _sort.sortBy && s.sort.order === _sort.order) || SORT_OPTIONS[0])
  const handleSortChange = (event) => {
    // console.log('sort changing: ', event)
    setSort(event.target.value)
    let res = sortProduct(event.target.value, filteredProducts)
    setFilteredProducts(res)
  }
  const [filter, setFilter] = useState(_filter)
  const [filteredProducts, setFilteredProducts] = useState(products)
  const [loaded, setLoaded] = useState(false)
  const [searchInput, setSearchInput] = useState('')
  const [isMobile, setIsMobile] = useState(false)
  const [parentCategoriesOpenList, setParentCategoriesOpenList] = useState([false, false, false, false, false])

  const [localCategories, setLocalCategories] = useState([])

  const checkCategories = (current) => {
    let global_categories = type === 'Product' ?
        config.product.categories :
        config.service.categories
      setLocalCategories(global_categories)
    if (type === 'Product' && !filter.categories.includes(current)) {
      filter.categories.push(current)
    }
  }

  useEffect(() => {
    checkCategories(category)
  }, [category])

  const handleChangeSearchInput = (e) => {
    setSearchInput(e.target.value)
  }
  const initList = () => {
    if (type === 'Service' && _filter['categories'].length > 0) {
      handleFilterChange('categories', _filter['categories'])
    }
  }

  useEffect(() => {
    console.log('at product listing: ', brands)
    initList()
    checkCategories()
    setLoaded(true)
  }, [])

  const handleFilterChange = (field, value) => {
    console.log('filter changed: ', field, value)
    switch (field) {
      case 'deals':
        setFilter({
          ...filter,
          deals: _.xor(filter.deals, [value])
        })
        break
      case 'categories':
        setFilter({
          ...filter,
          categories: _.xor(filter.categories, [value])
        })
        break
      case 'brandVerified':
        setFilter({
          ...filter,
          brandVerified: value
        })
        break
      case 'brands':
        setFilter({
          ...filter,
          brands: _.xor(filter.brands, [value])
        })
        break
      case 'strainTypes':
        setFilter({
          ...filter,
          strainTypes: _.xor(filter.strainTypes, [value])
        })
        break
      case 'priceRanges':
        setFilter({
          ...filter,
          priceRanges: toggleArray(filter.priceRanges, value)
        })
        break
      case 'weights':
        setFilter({
          ...filter,
          weights: _.xor(filter.weights, [value])
        })
        break
    }
  }

  const handleSearch = () => {
    if (searchInput === '') {
      let res = filterProduct(filter, products)
      setFilteredProducts(res)
    } else {
      let temp = filteredProducts.filter(item => item.name.toLowerCase().includes(searchInput.toLowerCase()))
      setFilteredProducts(temp)
    }
  }

  const clearAll = () => {
    setSearchInput('')
    let tempFilter = _filter
    tempFilter.categories = ''
    setFilter(tempFilter)

  }

  const filterProduct = (localFilter, localProducts) => {
      if (localFilter.categories[0] === undefined) {
        localFilter.categories = []
      }
    if (JSON.stringify(localFilter) !== '{}') {
        for (let key in localFilter) {
          if (localFilter.hasOwnProperty(key)) {
            // console.log('started: ', key, localFilter[key], localFilter[key].length > 0)
            if (localFilter[key].length > 0 || typeof localFilter[key] === 'boolean') {
              if (key === 'brandVerified') {
                if (localFilter[key] === true) {
                  localProducts = localProducts.filter(item => item[filter_key_map[key]] === true)
                }
              } else if (key === 'categories' || key === 'brands') {
                if (type === 'Service') {
                  if (key === 'categories') {
                    if (localFilter[key].length > 0) {
                      let tempList = []
                      for (let i = 0; i < localFilter[key].length; i ++) {
                        let list = localProducts.filter(item => item.category === localFilter[key][i])
                        tempList = tempList.concat(list)
                      }
                      localProducts = tempList
                    }
                  }
                } else {
                  let tempList = []
                  if (key === 'categories') {
                    for (let i = 0; i < localFilter[key].length; i ++) {
                      let list = localProducts.filter(item => {
                        // console.log('checking item slug: ', item, item[filter_key_map[key]])
                        if (!!item[filter_key_map[key]]) {
                          // console.log('checking here: ', item, filter_key_map[key], item[filter_key_map[key]])
                          if (item[filter_key_map[key]].includes(localFilter[key][i])) {
                            return item
                          }
                        }
                      })
                      tempList = tempList.concat(list)
                    }
                    localProducts = tempList
                  } else {
                    for (let i = 0; i < localFilter[key].length; i ++) {
                      let list = localProducts.filter(item => {
                        // console.log('checking item slug: ', item, item[filter_key_map[key]])
                        if (!!item[filter_key_map[key]]) {
                          // console.log('checking here: ', localFilter[key], item.brand.id)
                          if (item.brand.id.toString() === localFilter[key][i]) {
                            return item
                          }
                        }
                      })
                      tempList = tempList.concat(list)
                    }
                    localProducts = tempList
                  }
                }

              } else if (key === 'priceRanges') {
                let tempList = []
                for (let i = 0; i < localFilter[key].length; i ++) {
                  let list = localProducts.filter(item => item.price >= localFilter[key][i].min && item.price < localFilter[key][i].max)
                  tempList = tempList.concat(list)
                }
                localProducts = tempList
              } else {
                localProducts = localProducts.filter(item => {
                  if (localFilter[key].includes(item[filter_key_map[key]])) {
                    return item
                  }
                })
              }
            }
          }
        }
      } else {
        console.log('caught it !')
      }
      console.log('localFiltered: ', localProducts.length)
    return localProducts
  }

  const sortProduct = (localSort, localProducts) => {
    return sortByItem(localProducts, localSort.sort.sortBy, localSort.sort.order)
  }

  useEffect(() => {
    console.log('filtered products: ', filteredProducts)
  }, [filteredProducts])

  useEffect(() => {
    if (loaded) {
      let temp = filterProduct(filter, products)
      console.log('sorting : ', sort , temp)
      let res = sortProduct(sort, temp)
      setFilteredProducts(res)
    }
  }, [filter, loaded, sort, products])

  useEffect(() => {
    if (searchInput === '') {
      handleSearch()
    }
  }, [searchInput])

  const [mobileOpen, setMobileOpen] = useState(false);

  useEffect(() => {
    if (loaded) {
      setIsMobile(checkMobile())
    }
  }, [loaded])

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const drawerWidth = 240;

  const drawer = (
      <Box className={"p-5"} sx={{ textAlign: 'center' }}>
        <Button variant={'text'} className={'ml-auto w-auto'} onClick={clearAll}>
          {t('home:clearall')}
        </Button>
        <div className="mb-4 ">
          <div className="font-semibold text-sm mb-2">{t('home:filter_category')}</div>
          {
            localCategories.map(c => (
                <FormControlLabel
                    key={c.slug}
                    label={<span className={'text-sm'}>{t(`home:${c.slug}`)}</span>}
                    className={'flex items-center -mb-1'}
                    control={
                      <Checkbox
                          checked={!!filter.categories ? filter.categories.includes(c.slug) : false}
                          onChange={e => handleFilterChange('categories', c.slug)}
                          size={'small'} className={'text-primary-dark'}
                      />
                    }
                />
            ))
          }
        </div>
        <div className="mb-4">
          <div className="font-semibold text-sm mb-2">{t('home:amenities_BrandVerified')}</div>
          <FormControlLabel
              label={<span className={'text-sm'}>
            {t('home:amenities_BrandVerified')}
                <FontAwesomeIcon icon={solid('certificate')} className={'ml-2 text-primary'}/>
              </span>}
              className={'flex items-center -mb-1'}
              control={
                <Checkbox
                    checked={filter.brandVerified}
                    onChange={e => handleFilterChange('brandVerified', !filter.brandVerified)}
                    size={'small'} className={'text-primary-dark'}
                />
              }
          />
        </div>
        <div className="mb-4">
          {
            brands && brands.length > 0 ?
                <div className="font-semibold text-sm mb-2">{t('home:Brands')}</div> : null
          }
          {
            brands && brands.length > 0 ?
                brands.map(b => (
                    <FormControlLabel
                        key={b.slug}
                        label={<span className={'text-sm'}>{b.name}</span>}
                        className={'flex items-center -mb-1'}
                        control={
                          <Checkbox
                              checked={!!filter.brands ? filter.brands.includes(b.id) : false}
                              onChange={e => handleFilterChange('brands', b.id)}
                              size={'small'} className={'text-primary-dark'}
                          />
                        }
                    />
                )) : null
          }
        </div>
        {
          type==='Product' && <div className="mb-4">
            <div className="font-semibold text-sm mb-2">{t('home:filter_strainType')}</div>
            {
              STRAIN_TYPES.map(t => (
                  <FormControlLabel
                      key={t.value}
                      label={<span className={'text-sm'}>{t.label}</span>}
                      className={'flex items-center -mb-1'}
                      control={
                        <Checkbox
                            checked={!!filter.strainTypes ? filter.strainTypes.includes(t.value) : false}
                            onChange={e => handleFilterChange('strainTypes', t.value)}
                            size={'small'} className={'text-primary-dark'}
                        />
                      }
                  />
              ))
            }
          </div>
        }

        <div className="mb-4">
          <div className="font-semibold text-sm mb-2">{t('home:filter_priceRange')}</div>
          {
            PRICE_RANGES.map(r => {
              return (
                  <FormControlLabel
                      key={`${String(r.min)}-${String(r.max)}`}
                      label={<span className={'text-sm'}>{`${r.min === 0 ? t('home:under') : '$'+r.min}${r.min === 0 || r.max === 'Infinity' ? ' ' : ' - '}${r.max === 'Infinity' ? t('home:infinity') : '$'+r.max}`}</span>}
                      className={'flex items-center -mb-1'}
                      control={
                        <Checkbox
                            checked={!!filter.priceRanges ? filter.priceRanges.includes(r) : false}
                            onChange={e => handleFilterChange('priceRanges', r)}
                            size={'small'} className={'text-primary-dark'}
                        />
                      }
                  />
              )

            })
          }
        </div>
        {
          type==='Product' && <div className="mb-4">
            <div className="font-semibold text-sm mb-2">{t('home:filter_weight')}</div>
            {
              WEIGHTS.map(w => (
                  <FormControlLabel
                      key={w}
                      label={<span className={'text-sm'}>{w === 'Each' ? t('home:each') : w}</span>}
                      className={'flex items-center -mb-1'}
                      control={
                        <Checkbox
                            checked={!!filter.weights ? filter.weights.includes(w) :false}
                            onChange={e => handleFilterChange('weights', w)}
                            size={'small'} className={'text-primary-dark'}
                        />
                      }
                  />
              ))

              // <Checkbox
              //   checked={!!filter.brands ? filter.brands.includes(b.id) : false}
              //   onChange={e => handleFilterChange('brands', b.id)}
              //   size={'small'} className={'text-primary-dark'}
              // />
            }
          </div>
        }
      </Box>
  );

  const renderMobileFilter = () => {
    return (
        <>
          <div className="w-auto">
            <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                sx={{ display: { sm: 'none' } }}
                className="flex flex-row justify-start"
            >
              <FontAwesomeIcon icon={solid('sliders')}/>
              <span className={'ml-2 font-semibold min-w-[32px]'}>{t('home:filter')}</span>
            </IconButton>
          </div>
          <Box component="nav">
            <Drawer
                // container={container}
                variant="temporary"
                open={mobileOpen}
                onClose={handleDrawerToggle}
                ModalProps={{
                  keepMounted: true, // Better open performance on mobile.
                }}
                sx={{
                  display: { xs: 'block', sm: 'none' },
                  '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                }}
            >
              {drawer}
            </Drawer>
          </Box>
        </>
    )
  }

  const renderCollapseList = (c, index) => {
    // console.log('here: ', c)
    let parent = c.subcategories.find(item => item.slug === c.slug)
    let pushedParent = parent !== undefined
        if (!pushedParent) {
      c.subcategories.push({
        "name": t('home:allAbove'),
        "slug": c.slug,
        "parent_category": c.slug,
      },)
    }

    return (
        <CollapseList category={category} index={index} key={c.slug} t={t} name={t(`home:${c.slug}`)} subList={c.subcategories} filter={filter} handleFilterChange={handleFilterChange} />
    )
  }


  return (
    <div className={isMobile ? "flex flex-col" : "flex gap-8"}>
      <div className={isMobile ? "flex flex-col w-full" : "w-3/12"}>
        {
          isMobile ?
              renderMobileFilter() :
              <>
                <div className="flex items-center pb-2 border-b mb-4">
                  <FontAwesomeIcon icon={solid('sliders')}/>
                  <span className={'ml-2 font-semibold min-w-[32px]'}>{t('home:filter')}</span>
                  <Button variant={'text'} className={isMobile ? 'ml-auto w-auto' : 'ml-auto min-w-full'} onClick={clearAll}>
                    {t('home:clearall')}
                  </Button>
                </div>
                <div >
                  <div className="mb-4 ">
                    <div className="font-semibold text-sm mb-2">{t('home:filter_category')}</div>
                    {

                      localCategories.map((c, index) =>

                          type === 'Product' ?
                              renderCollapseList(c, index) :
                              <FormControlLabel
                                  key={c.slug}
                                  label={<span className={'text-sm'}>{t(`home:${c.slug}`)}</span>}
                                  className={'flex items-center -mb-1'}
                                  control={
                                    <Checkbox
                                        checked={!!filter.categories ? filter.categories.includes(c.slug) : false}
                                        onChange={e => handleFilterChange('categories', c.slug)}
                                        size={'small'} className={'text-primary-dark'}
                                    />
                                  }
                              />
                      )
                    }

                  </div>
                  <div className="mb-4">
                    <div className="font-semibold text-sm mb-2">{t('home:amenities_BrandVerified')}</div>
                    <FormControlLabel
                        label={<span className={'text-sm'}>
              {t('home:amenities_BrandVerified')}
                          <FontAwesomeIcon icon={solid('certificate')} className={'ml-2 text-primary'}/>
                </span>}
                        className={'flex items-center -mb-1'}
                        control={
                          <Checkbox
                              checked={filter.brandVerified}
                              onChange={e => handleFilterChange('brandVerified', !filter.brandVerified)}
                              size={'small'} className={'text-primary-dark'}
                          />
                        }
                    />
                  </div>
                  <div className="mb-4">
                    <div className="font-semibold text-sm mb-2">{t('home:Brands')}</div>
                    {
                      brands ?
                          brands.map(b => (
                              <FormControlLabel
                                  key={b.slug}
                                  label={<span className={'text-sm'}>{b.name}</span>}
                                  className={'flex items-center -mb-1'}
                                  control={
                                    <Checkbox
                                        checked={!!filter.brands ? filter.brands.includes(b.id) : false}
                                        onChange={e => handleFilterChange('brands', b.id)}
                                        size={'small'} className={'text-primary-dark'}
                                    />
                                  }
                              />
                          )) : null
                    }
                  </div>
                  {
                    type==='Product' && <div className="mb-4">
                      <div className="font-semibold text-sm mb-2">{t('home:filter_strainType')}</div>
                      {
                        STRAIN_TYPES.map(t => (
                            <FormControlLabel
                                key={t.value}
                                label={<span className={'text-sm'}>{t.label}</span>}
                                className={'flex items-center -mb-1'}
                                control={
                                  <Checkbox
                                      checked={!!filter.strainTypes ? filter.strainTypes.includes(t.value) : false}
                                      onChange={e => handleFilterChange('strainTypes', t.value)}
                                      size={'small'} className={'text-primary-dark'}
                                  />
                                }
                            />
                        ))
                      }
                    </div>
                  }

                  <div className="mb-4">
                    <div className="font-semibold text-sm mb-2">{t('home:filter_priceRange')}</div>
                    {
                      PRICE_RANGES.map(r => {
                        return (
                            <FormControlLabel
                                key={`${String(r.min)}-${String(r.max)}`}
                                label={<span className={'text-sm'}>{`${r.min === 0 ? t('home:under') : '$'+r.min}${r.min === 0 || r.max === 'Infinity' ? ' ' : ' - '}${r.max === 'Infinity' ? t('home:infinity') : '$'+r.max}`}</span>}
                                className={'flex items-center -mb-1'}
                                control={
                                  <Checkbox
                                      checked={!!filter.priceRanges ? filter.priceRanges.includes(r) : false}
                                      onChange={e => handleFilterChange('priceRanges', r)}
                                      size={'small'} className={'text-primary-dark'}
                                  />
                                }
                            />
                        )

                      })
                    }
                  </div>
                  {
                    type==='Product' && <div className="mb-4">
                      <div className="font-semibold text-sm mb-2">{t('home:filter_weight')}</div>
                      {
                        WEIGHTS.map(w => (
                            <FormControlLabel
                                key={w}
                                label={<span className={'text-sm'}>{w === 'Each' ? t('home:each') : w}</span>}
                                className={'flex items-center -mb-1'}
                                control={
                                  <Checkbox
                                      checked={!!filter.weights ? filter.weights.includes(w) :false}
                                      onChange={e => handleFilterChange('weights', w)}
                                      size={'small'} className={'text-primary-dark'}
                                  />
                                }
                            />
                        ))

                        // <Checkbox
                        //   checked={!!filter.brands ? filter.brands.includes(b.id) : false}
                        //   onChange={e => handleFilterChange('brands', b.id)}
                        //   size={'small'} className={'text-primary-dark'}
                        // />
                      }
                    </div>
                  }
                </div>
              </>
        }
        {/*{*/}
        {/*  deals === undefined ? null :*/}
        {/*      <div className="mb-4">*/}
        {/*        <div className="font-semibold text-sm mb-2">Deals</div>*/}
        {/*        {*/}
        {/*          deals.map(d => (*/}
        {/*              <FormControlLabel*/}
        {/*                  key={d.slug}*/}
        {/*                  label={<span className={'text-sm'}>{d.name}</span>}*/}
        {/*                  className={'flex items-center -mb-1'}*/}
        {/*                  control={*/}
        {/*                    <Checkbox*/}
        {/*                        checked={filter.deals.includes(d.slug)}*/}
        {/*                        onChange={e => handleFilterChange('deals', d.slug)}*/}
        {/*                        size={'small'} className={'text-primary-dark'}*/}
        {/*                    />*/}
        {/*                  }*/}
        {/*              />*/}
        {/*          ))*/}
        {/*        }*/}
        {/*      </div>*/}
        {/*}*/}

      </div>
      <div className="flex-grow">
        <FormControl fullWidth size={'small'} className={'mt-1'}>
          {/*<InputLabel htmlFor="menu-search">Search this menu</InputLabel>*/}
          <OutlinedInput
            // label="Search this menu"
            placeholder={t('home:searchThisMenu')}
            id={'menu-search'}
            onChange={handleChangeSearchInput}
            onKeyDown={(ev) => {
              if (ev.key === 'Enter') {
                // Do code here
                handleSearch()
                ev.preventDefault();
              }
            }}
            value={searchInput}
            endAdornment={
              <FontAwesomeIcon className="cursor-pointer" icon={solid('search')} onClick={handleSearch}/>
            }
          />
        </FormControl>
        <div className="flex py-4 items-center">
          {loaded ? `${t('home:found')} ${filteredProducts.length}` :  ''}
          <Select
            value={sort}
            onChange={handleSortChange}
            size={'small'} className={"w-52 text-sm bg-white ml-auto"}
          >
            {
              SORT_OPTIONS.map(option => (
                <MenuItem key={option.label} value={option}>{t(`home:${option.key}`)}</MenuItem>
              ))
            }
          </Select>
        </div>
        <div className={isMobile ? "grid grid-cols-1" : "grid grid-cols-3 gap-3"}>
          {
            filteredProducts.map(product => (
              <ProductCard key={product.id + product.slug} data={product} baseUrl={baseUrl} type={type}/>
            ))
          }
        </div>
      </div>
    </div>
  )
}
