import * as React from 'react';
import Box from '@mui/material/Box';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUp from '@mui/icons-material/KeyboardArrowUp';
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import {useEffect, useState} from "react";
import config from "../data/config.json";

export default function CustomizedList(props) {
    const {name, subList, filter, handleFilterChange, t, category, index} = props
    const [open, setOpen] = React.useState(false);

    useEffect(() => {
        if (!!category) {
            let parents = config.product.categories.map(item => item.slug)
            let openStatus = parents.map(item => category.includes(item))
            setOpen(openStatus[index])
        }
    }, [category])

    return (
        <Box sx={{ display: 'flex' }}>
            <Box
                sx={{
                    pb: open ? 2 : 0,
                }}
            >
                <ListItemButton
                    alignItems="flex-start"
                    onClick={() => setOpen(!open)}
                    sx={{
                        // px: 3,
                        // pt: 2.5,
                        // pb: open ? 0 : 2.5,
                        '&:hover, &:focus': { '& svg': { opacity: open ? 1 : 0 } },
                    }}
                >
                    <ListItemText
                        primary={name}
                        primaryTypographyProps={{
                            fontSize: 14,
                            fontWeight: open ? 'bold' : 'medium',
                            lineHeight: '20px',
                            mb: '2px',
                            // color: open ? '#108085' : 'black'
                        }}
                        secondaryTypographyProps={{
                            noWrap: true,
                            fontSize: 12,
                            lineHeight: '16px',
                            color: 'black',
                        }}
                        sx={{ my: 0 }}
                    />
                    <KeyboardArrowDown
                        sx={{
                            mr: -1,
                            opacity: 0,
                            transform: open ? 'rotate(-180deg)' : 'rotate(0)',
                            transition: '0.2s',
                        }}
                    />
                </ListItemButton>

                {open &&
                subList.map((item) => (
                      <FormControlLabel
                          key={item.slug+item.name}
                          label={<span className={'text-sm'}>{item.name === t('home:allAbove') ? t('home:allAbove') : t(`home:${item.slug}`)}</span>}
                          className={'flex items-center -mb-1'}
                          control={
                            <Checkbox
                                checked={!!filter.categories ? filter.categories.includes(item.slug) : false}
                                onChange={e => handleFilterChange('categories', item.slug)}
                                size={'small'} className={'text-primary-dark'}
                            />
                          }
                      />
                ))}
            </Box>
        </Box>
    );
}
