import React, { useEffect, useState } from 'react';
import {Button, MenuItem, Modal, Select} from "@mui/material";
import {useSelector} from "react-redux";
import Image from "next/image";
import {useTranslation} from "next-i18next";
import { TextField } from '@mui/material'
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import OutlinedInput from "@mui/material/OutlinedInput";
import { addToBag } from '../../utils/helper'


export default function OrderModal(props) {
    const { modalOpen, setModalOpen, product } = props
    const [loading, setLoading] = useState(true)
    const [amount, setAmount] = useState(1)
    const locations = useSelector(state => state.map.locations)
    const [msg, setMsg] = useState('')

    const [pickupLocation, setPickupLocation] = useState({id: 0, name: ''})
    const [availableLocations, setAvailableLocations] = useState([])

    const { t, i18n } = useTranslation()

    const initAvailableLocations = () => {
        if (!!product.locations && product.locations.length > 0 && typeof product.locations !== 'string') {
            let tempLocations = product.locations.map(item => {
                if (item.id) {
                    let temp =  locations.find(location => location.id === item)
                    if (!!temp) {
                        return temp
                    } else {
                        return null
                    }
                } else {
                    return null
                }
            })
            setAvailableLocations(tempLocations.filter(item => item !== null))
        }
    }

    useEffect(() => {
        setLoading(false)
        initAvailableLocations()
    }, []);

    useEffect(() => {
        initAvailableLocations()
    },[locations])

    useEffect(() => {
        if (availableLocations.length > 0) {
            setPickupLocation(availableLocations[0])
        }
    }, [availableLocations])



    const handleOrder = async () => {
        let user = await localStorage.getItem('user')
        let res = await addToBag(user, {
            productInfo: product,
            amount,
        }, pickupLocation.id
        )
        if (res.success) {
            handleCancel()
        } else {
            setMsg(res.msg)
        }
    };

    const handleCancel = () => {
        setModalOpen(false);
    };

    const handleChange = (e) => {
        setAmount(e.target.value)
    }

    const minusOne = () => {
        setAmount(amount - 1)
    }
    const addOne = () => {
        setAmount(amount + 1)
    }

    const handleLocationChange = (e) => {
        setPickupLocation(e.target.value)
    }

    return (
        <>
            {loading && <div className="fixed z-[1299] bg-gray-100 inset-0"></div>}
            <Modal
                open={modalOpen}
                onClose={e => setModalOpen(false)}
                className={'flex p-6'}
                componentsProps={{
                    backdrop: {
                        className: "bg-gray-20"
                    }
                }}
            >
                <div className="bg-white m-auto shadow-lg p-8 rounded max-w-md outline-0 w-full flex flex-col items-center text-center">
                    <div className="w-full mb-6 flex text-start font-bold ">
                        {product.name}
                    </div>
                    <>
                        <div className="flex items-center justify-center w-full gap-4 mb-5">
                            <div className="text-black-500 font-bold pr-5">
                                {`$${product.price_compare > 0 ? product.price_compare : product.price}`}
                            </div>
                            <div className="text-gray-500 ">
                                <OutlinedInput
                                    className="p-0"
                                    size='small'
                                    type={'number'}
                                    value={amount}
                                    onChange={handleChange}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <div className="flex flex-col inline-block">
                                                <IconButton
                                                    className="m-0 p-0 rounded-none"
                                                    aria-label="toggle password visibility"
                                                    onClick={addOne}
                                                    edge="end"
                                                >
                                                    <ArrowDropUpIcon />
                                                </IconButton>
                                                <IconButton
                                                    className="m-0 p-0 rounded-none"
                                                    aria-label="toggle password visibility"
                                                    onClick={minusOne}
                                                    edge="end"
                                                >
                                                    <ArrowDropDownIcon />
                                                </IconButton>
                                            </div>
                                        </InputAdornment>
                                    }
                                />
                            </div>
                        </div>
                        <div className="flex w-full gap-4 mb-10 justify-center items-center">
                            <div className="text-black-500 pr-5">
                                {t('home:pickupLocation')}
                            </div>
                            <div className="flex py-4 items-center">
                                <Select
                                    value={pickupLocation.name}
                                    onChange={handleLocationChange}
                                    size={'small'} className={"w-52 text-sm bg-white ml-auto"}
                                >
                                    {
                                        availableLocations.length > 0 &&
                                        availableLocations.map(loc => (
                                            <MenuItem key={loc.id} value={loc.name}>{loc.name}</MenuItem>
                                        ))
                                    }
                                </Select>
                            </div>
                        </div>
                        <div className="flex w-full gap-4">
                            <Button variant="outlined" fullWidth onClick={handleOrder}>
                                {t('home:addToBag')}
                            </Button>
                            <Button className={'!bg-[#108085]'} variant="contained" fullWidth onClick={handleCancel}>
                                {t('home:cancel')}
                            </Button>
                        </div>
                        {
                            msg !== '' ?
                                <div className="text-black-500 ">
                                    {msg}
                                </div> : null
                        }
                    </>
                </div>
            </Modal>
        </>
    )
}
